<template>
    <div class="coach_vip">
        <content-title :nav="nav"></content-title>
        <el-form :inline="true">
            <el-form-item label="手机号">
                <el-input v-model="phone" placeholder="请输入手机号"></el-input>
                <el-button style="margin-left: 20px;" type="warning" @click="currentPage = 1, GetCoach()" plain>搜索</el-button>
            </el-form-item>
        </el-form>

        <auto-table :DataList="DataList" :option="option" @changePage="changeCurrentPage" :currentPage="currentPage"
            :showPage="true" :total="total">
            <el-table-column label="操作" width="120">
                <template slot-scope="scope"> <el-button type="warning" size="small" @click="jumpcoachDetails(scope.row.id)"
                        plain>查看教练</el-button></template>
            </el-table-column>
        </auto-table>
    </div>
</template>

<script>
export default {
    name:'coach_vip',
    data() {
        return {
            nav: { firstNav: "教练管理中心", secondNav: "会员教练列表" },
            DataList: [],
            option: [{
                name: '教练姓名',
                value: 'real_name',
            }, {
                name: '手机号',
                value: 'phone',
            },
             {
                name: '城市',
                value: 'city',
            },
            {
                name: '开始时间',
                value: 'effect_time',
            },
             {
                name: '过期时间',
                value: 'expire_time',
            }
            ],
            Gradevl: '未绑定机构教练',
            Grade: [{
                name: '未绑定机构教练',
                id: 0,
            }, {
                name: '机构教练',
                id: 1,
            }],
            currentPage: 1,
            total: 0,
            is_binding: 0,
            phone: "",
        }
    },
    mounted() {
        this.GetCoach()
    },

    methods: {
        jumpcoachDetails(e) {
            this.$router.push({
                name: 'coachDetails',
                query: {
                    id: e
                }
            })
        },
        changeCurrentPage(v) {
            this.currentPage = v
             this.GetCoach()
        },
        GetCoach() {
            let data = {
                identity_type: 2,
                currentPage:this.currentPage,
                status:2
            }
            if (this.phone) {
                data.phone = this.phone
            }
            this.$get('/user/coach/queryManagerListPage', data).then(res => {
                this.DataList = res.data.data.rows
                this.total = res.data.data.total
            })
        }
    }
}
</script>

<style scoped>
.coach_vip {
    background-color: #ffffff;
    padding: 30px;
}
</style>